import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import * as types from '../types';

export const setDrillerMenuSyncChartsData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_DRILLER_MENU_SYNC_CHARTS_DATA,
    payload: data,
  });
};

export const getDrillerLogs = () => async (dispatch) => {
  let logs = [];
  const response = async (res) => {
    logs = await res.data;
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getDrillerLogs(response, error);

  dispatch({
    type: types.GET_DRILLER_LOGS,
    payload: logs,
  });
};

const setFetchingWobValueStatus = (status) => (dispatch) => {
  dispatch({
    type: types.SET_WOB_VALUE_STATUS,
    payload: status,
  });
};

export const getReferenceWOB = () => async (dispatch) => {
  let value = '';

  dispatch(setFetchingWobValueStatus('loading'));

  const response = async (res) => {
    value = res?.data?.[0]?.result || res?.data?.result || '';
    dispatch(setFetchingWobValueStatus(''));
  };

  const error = (err) => {
    dispatch(setFetchingWobValueStatus('error'));
    ApiErrorHandler(err);
  };

  await httpService.getWobReference(response, error);

  dispatch({
    type: types.GET_WOB_VALUE,
    payload: value,
  });
};
