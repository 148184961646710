// websocketService.js
import { EventEmitter } from 'events';

class WebsocketTriggerService {
  constructor() {
    this.socket = null;
    this.emitter = new EventEmitter();
  }

  connect() {
    if (!this.socket) {
      this.socket = new WebSocket(process.env.REACT_APP_API_SOCKET_RELOAD);
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.emitter.emit('message', data);
      };
      this.socket.onopen = () => console.log("WebSocket connected");
      this.socket.onclose = () => console.log("WebSocket disconnected");
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  send(eventMessage) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ event: eventMessage }));
    }
  }

  onMessage(callback) {
    this.emitter.on('message', callback);
  }

  offMessage(callback) {
    this.emitter.off('message', callback);
  }
}

const websocketTriggerService = new WebsocketTriggerService();
export default websocketTriggerService;