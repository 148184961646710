import { Input, Space, Card, Typography, Form, Button } from 'antd';
import { httpService } from 'api/api';
import ButtonWithIcon from 'components/shared/ButtonWithIcon';
import LableInputs from 'components/shared/LableInputs';
import ApiToastHandle from 'containers/ApiToastHandle';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiErrorHandler, { basePathSubDir } from 'utils/ApiErrorHandler';

const { Title, Text } = Typography;

const AccountSettings = () => {
  const { user } = useSelector((state) => state.general);

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    loadingUpdateName: false,
    loadingChangePass: false,
  });

  useEffect(() => {
    if (user) {
      setState((prevState) => ({
        ...prevState,
        email: user?.email,
        name: user?.name,
      }));
    }
  }, [user]);

  const handleChange = (name, event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitResetPasword = (e) => {
    e.preventDefault();
    const { password, confirm_password } = state;

    if (password !== confirm_password) {
      ApiToastHandle(200, 'Passwords do not match', 'warning');
      return;
    }

    setState((prev) => ({
      ...prev,
      loadingChangePass: true,
    }));

    const data = {
      password,
      confirm_password,
    };

    const response = (res) => {
      if (res.status === 200) {
        ApiToastHandle(200, 'Password updated successfully', 'success');
        ApiToastHandle(200, 'Logging out...', 'success');
        setState((prev) => ({
          ...prev,
          password: '',
          confirm_password: '',
          loadingChangePass: false,
        }));
        Cookies.remove('userToken');
        Cookies.remove('is_active');
        Cookies.remove('role');
        Cookies.remove('username');
        Cookies.remove('activeTab');
        Cookies.remove('currentSetup');
        Cookies.remove('dark_number');
        setTimeout(() => {
          window.location.replace(`${basePathSubDir}/login`);
        }, 3000);
      }
    };

    const error = (err) => {
      setState((prev) => ({
        ...prev,
        loadingChangePass: false,
      }));
      ApiErrorHandler(err);
    };

    httpService.resetPassword(response, error, data);
  };

  const updateUsername = () => {
    const userId = Cookies.get('dark_number');
    const { name } = state;

    if (!name) {
      ApiToastHandle(200, 'Fill the name input', 'warning');
      return;
    }

    if (!userId) {
      ApiToastHandle(200, `Can't update user information, please login again and try`, 'warning');
      return;
    }

    setState((prev) => ({
      ...prev,
      loadingUpdateName: true,
    }));

    const response = (res) => {
      if (res.status === 200) {
        ApiToastHandle(200, 'Information updated successfully', 'success');
        setState((prev) => ({
          ...prev,
          name: res?.data?.name,
          loadingUpdateName: false,
        }));
      }
    };

    const error = (err) => {
      setState((prev) => ({
        ...prev,
        loadingUpdateName: false,
      }));
      ApiErrorHandler(err);
    };

    httpService.updateUserInfo(response, error, userId, { name });
  };

  return (
    <div className="w-full flex flex-wrap gap-2">
      {/* Account Info Section */}
      <Card
        title={<Title level={4} className="mb-0">Account Info</Title>}
        className="w-full shadow-sm rounded-lg flex-1"
      >
        <Space direction="vertical" className="w-full">
          <Form layout="vertical">
            <Form.Item label={<Text strong>Full Name</Text>}>
              <Input
                name="name"
                value={state.name}
                required
                className="rounded-md"
                onChange={(e) => handleChange('name', e)}
              />
            </Form.Item>
            <Form.Item label={<Text strong>Email</Text>}>
              <Input
                name="email"
                type="email"
                value={state.email}
                required
                className="rounded-md"
                onChange={(e) => handleChange('email', e)}
                autoComplete="off"
                disabled
              />
            </Form.Item>
            <Form.Item>
            <ButtonWithIcon
                title="Update"
                onClick={updateUsername}
                loading={state.loadingUpdateName}
                className="bg-primary text-white font-semibold border-primary hover:text-white hover:bg-primary/90 hover:border-primary/90 disabled:bg-gray-300 disabled:border-gray-300 rounded-sm min-w-[100px]"
              />
            </Form.Item>
          </Form>
        </Space>
      </Card>
      {/* Change Password Section */}
      <Card
        title={<Title level={4} className="mb-0">Change Password</Title>}
        className="w-full shadow-sm rounded-lg flex-1"
      >
        <Form layout="vertical" onSubmit={submitResetPasword}>
          <Form.Item label={<Text strong>New Password</Text>}>
            <Input.Password
              name="password"
              required
              className="rounded-md"
              placeholder="Enter new password"
              onChange={(e) => handleChange('password', e)}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item label={<Text strong>Confirm Password</Text>}>
            <Input.Password
              name="confirm_password"
              required
              className="rounded-md"
              placeholder="Confirm new password"
              onChange={(e) => handleChange('confirm_password', e)}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
          <ButtonWithIcon
                  title="Confirm"
                  type="submit"
                  onClick={submitResetPasword}
                  loading={state.loadingChangePass}
                  className="bg-primary text-white font-semibold border-primary hover:text-white hover:bg-primary/90 hover:border-primary/90 disabled:bg-gray-300 disabled:border-gray-300 rounded-sm min-w-[100px]"
                />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AccountSettings;