import React, { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fitToScreen } from 'hooks/FitToScreem';
import LazyLoadingIndicator from 'components/shared/LazyLoadingIndicator';
import useDarkMode from 'hooks/useDarkMode';
import { currentUser } from 'store/actions/general';
import { getSetting } from 'store/actions/settings';
import websocketTriggerService from 'workers/websocketTriggerService';
import { setConstantsList } from 'store/actions/liveData';
import getLogo from 'utils/getLogo';
import UserPopover from './components/UserPopover';
import DrawerMenu from './includes/DrawerMenu';

const SetupProjectLayout = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector((state) => state.settings.settings);
  const { clientLogo } = useSelector((state) => state.settings);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isDarkMode] = useDarkMode();
  const logo = getLogo(clientLogo, settingsData, isDarkMode);

  // Fixed navigation height to match DrawerMenu calculations
  const navHeight = 70; // Approximate height in pixels based on typical header size

  useEffect(() => {
    dispatch(currentUser());
    dispatch(getSetting());

    // Connect to WebSocket
    websocketTriggerService.connect();

    // Handle WebSocket messages
    const handleSocketMessage = (data) => {
      const message = typeof data === 'string' ? JSON.parse(data) : data;
      const event = message?.event;

      if (event?.includes('SETUP')) {
        dispatch(setConstantsList(event));
      }
    };

    // Register event listeners
    websocketTriggerService.onMessage(handleSocketMessage);

    // Handle error and disconnect events
    if (typeof websocketTriggerService.onError === 'function') {
      websocketTriggerService.onError((error) => {
        console.log('WebSocket error:', error);
      });
    } else {
      console.warn('websocketTriggerService.onError is not implemented');
    }

    if (typeof websocketTriggerService.onDisconnect === 'function') {
      websocketTriggerService.onDisconnect(() => {
        console.log('Disconnected from WebSocket');
        setTimeout(() => {
          websocketTriggerService.connect(); // Attempt reconnection
        }, 3000);
      });
    } else {
      console.warn('websocketTriggerService.onDisconnect is not implemented');
    }

    return () => {
      websocketTriggerService.offMessage(handleSocketMessage);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY); // Update scroll position
    };

    // Add event listener on mount
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="bg-gray-100 text-primaryDark font-normal w-screen h-screen max-h-screen overflow-hidden dark:bg-darker dark:text-white">
      <ul
        className="bg-white py-3 px-4 m-2 rounded-md border border-gray-200 flex items-center justify-between dark:bg-dark dark:border-lightDark"
        style={{ boxShadow: '0px 4px 16px rgba(153, 153, 153, 0.2)', height: `${navHeight}px` }}
      >
        <div className="flex justify-start items-center gap-x-4">
          <div>
            {logo ? (
              <div className="w-fit max-w-28 min-w-12">
                <img
                  src={`data:image/png;base64,${logo}`}
                  className="h-[23px] md:h-[33px] lg:h-[43px] w-full"
                  alt="mainlogo saxon"
                />
              </div>
            ) : (
              <h2 className="text-2xl font-semibold p-0 m-0 mb-2">{settingsData?.company_name || 'saxon'}</h2>
            )}
          </div>
        </div>
        <UserPopover isDarkMode={isDarkMode} />
      </ul>
      <div
        className="bg-white w-full flex rounded-md border border-gray-200 m-2 dark:bg-dark dark:text-white dark:border-lightDark"
        style={{
          ...fitToScreen,
          height: `calc(100vh - ${navHeight + 20}px)`, // Matches DrawerMenu's base height calculation
        }}
      >
        {settingsData?.initialized && (
          <div
            className={`sticky top-0 max-h-screen z-10 transition-all duration-500 ease-in-out ${
              scrollPosition > 10 ? 'h-[calc(100vh-15px)]' : 'h-[calc(100vh-125px)]'
            }`}
          >
            <DrawerMenu />
          </div>
        )}
        <Suspense fallback={<LazyLoadingIndicator />}>
          <div className="flex items-start gap-x-2 h-full w-full flex-1">
            <div className="flex-1 w-full h-full">
              <Outlet />
            </div>
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default SetupProjectLayout;
