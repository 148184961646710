import React from 'react';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Add, Remove } from 'components/icons';
import { useSearchParams } from 'react-router-dom';

const SettingPopover = ({
  popoverRef,
  listOfViews,
  selectedViewId,
  settings,
  editView,
  isDarkMode,
  handleEditViewItem,
  setIsPopcnfirmDeletOpen,
  removeView,
  handleEditView,
  onSelectView,
}) => (
  <div
    ref={popoverRef}
    style={{ zIndex: 99 }}
    className="costume-wrapp-shadow rounded-md pb-4 pt-2 px-4 absolute min-w-[200px] max-w-[280px] max-h-[270px]  top-12 -left-24 bg-white dark:bg-dark text-xs"
  >
    <h4 className=" border-b font-semibold pb-2">views setup</h4>
    <ul className="max-h-[180px] overflow-auto border-b my-1 pb-4">
      {listOfViews &&
        listOfViews.map((item) => (
          <li
            key={`${item.id}-${item?.name}-${item.is_driller_menu}`}
            className="flex  items-baseline justify-between mb-0 py-1 pr-2"
          >
            <button
              type="button"
              onClick={() => onSelectView(item.name)}
              disabled={window.location.pathname.includes('create-view')}
              className={`flex justify-start items-center gap-x-1 max-w-[210px] truncate  ${
                (selectedViewId === item.id || settings?.selected_view_id === item.id) &&
                !editView.delete &&
                !editView.createNew &&
                // String(item.is_driller_menu).toLowerCase() === is_driller_menu_query_param &&
                'text-primary font-semibold capitalize'
              }`}
            >
              {(selectedViewId === item.id || settings?.selected_view_id === item.id) &&
                !editView.delete &&
                !editView.createNew && (
                  // String(item.is_driller_menu).toLowerCase() === is_driller_menu_query_param &&
                  <span className="w-[5px] h-[5px] rounded-full bg-primary" />
                )}
              {item.name}
              <span className="text-warning text-[9px]">{item.is_driller_menu ? '(Driller)' : ''}</span>
            </button>
            {!editView.delete && !editView.createNew && (
              <EditOutlined
                style={{ fontSize: 12, color: `${isDarkMode ? '#FFFFFF' : 'gray'}` }}
                onClick={() => handleEditViewItem(item.id, item.is_driller_menu)}
              />
            )}
            {editView.delete &&
              !editView.createNew &&
              item.name.toLowerCase() !== 'default' &&
              !item.name?.toLowerCase() !== (':default') && (
                <Popconfirm
                  title="Are you sure to delete this view?"
                  placement="left"
                  onOpenChange={(e) => setIsPopcnfirmDeletOpen(e)}
                  onConfirm={() => removeView(item.id, item)}
                  okText="Yes"
                  cancelText="No"
                  overlayStyle={{ zIndex: 9999999 }}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  okButtonProps={{ style: { background: 'red', borderColor: 'red', boxShadow: 'none' } }}
                >
                  <Button
                    type="link"
                    size="small"
                    className="p-0"
                    icon={<DeleteOutlined style={{ color: '#E84749', fontSize: 12 }} />}
                  />
                </Popconfirm>
              )}
          </li>
        ))}
    </ul>
    <div className="flex items-start space-x-1">
      <Button
        type="ghost"
        onClick={() => handleEditView('createNew')}
        className="flex flex-col items-center p-0 m-0 text-[10px] gap-0 justify-start"
      >
        <span>
          <Add color={isDarkMode ? '#FFFFFF' : ''} />
        </span>
        create new
      </Button>
      <button
        type="button"
        onClick={() => handleEditView('delete')}
        className={`flex flex-col items-center p-0 m-0 text-[10px] gap-0 justify-start ${
          editView.delete && 'text-error'
        }`}
      >
        <span>
          {/* eslint-disable-next-line no-nested-ternary */}
          <Remove color={editView.delete ? '#E84749' : isDarkMode ? '#fff' : ''} />
        </span>
        delete
      </button>
    </div>
  </div>
);

export default SettingPopover;
