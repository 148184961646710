import React from 'react';
import { NotificationError, NotificationInfo, NotificationSuccess, NotificationWarning } from 'components/icons';

const Notification = ({ type = 'success', text, status }) => {
  const errorType = (type) => {
    switch (type) {
      case 'success':
        return (
          <div className="p-1 rounded-sm bg-success w-fit">
            <NotificationSuccess />
          </div>
        );
      case 'error':
        return (
          <div className="p-1 rounded-sm bg-error w-fit overflow-hidden">
            <NotificationError />
          </div>
        );
      case 'warning':
        return (
          <div className="p-1 rounded-sm bg-warning w-fit">
            <NotificationWarning />
          </div>
        );
      case 'info':
        return (
          <div className="p-1 rounded-sm bg-info w-fit">
            <NotificationInfo />
          </div>
        );
      default:
        return (
          <div className="p-1 rounded-sm bg-success w-fit">
            <NotificationSuccess />
          </div>
        );
    }
  };

  return (
    <div
      className={`
        absolute
        w-full
        bottom-0
        top-0
        left-0
        flex
        items-start
        py-2
        px-2
        space-x-2
        text-${type}
        bg-${type}
        dark:bg-primaryDark
        dark:text-white
        bg-opacity-10
        dark:border
        dark:border-none
        `}
    >
      <div className="rounded-sm">{errorType(type)}</div>
      <div className="space-y-0 leading-[1]">
        <div className="font-bold">{type}</div>
        <p className="text-primaryDark dark:text-white text-sm h-full overflow-hidden text-ellipsis max-w-[250px] line-clamp-2 leading-[1.1]">
          {text}
        </p>

        {/* {status === 400 && (
          <p className="text-primaryDark text-sm h-full">
            {typeof text === 'object' ? Object.entries(text).map((err) => `${err[0]}: ${err[1]}`) : text}
          </p>
        )}
        {status === 500 && <p className="text-primaryDark text-sm h-full">{text}</p>}
        {status !== 500 && status !== 400 && (
          <p className="text-primaryDark text-sm h-full">
            {typeof text === 'object'
              ? Object.entries(text).map((err) =>
                  // eslint-disable-next-line valid-typeof
                  typeof err === 'text'
                    ? `${err[0]}: ${err[1]}`
                    : Object.entries(err[1]).map((errobj) => `${errobj[0]}: ${errobj[1]}`)
                )
              : text}
          </p>
        )} */}
      </div>
    </div>
  );
};

export default Notification;
