import { Button } from 'antd';
import React from 'react';

const ButtonWithIcon = ({ onClick, icon, title, className = '', type, disabled, width, loading }) => (
  <Button
    loading={loading}
    htmlType={type}
    disabled={disabled}
    onClick={onClick}
    style={{ boxShadow: 'none' }}
    className={`
      ${className}
      rounded
      capitalize
      hover:opacity-100
      px-4
      text-sm
      ${width || 'w-fit'} 
      ${
        disabled
          ? 'border border-gray-300 bg-gray-300 text-white hover:bg-gray-300 hover:border-gray-300 hover:text-white'
          : `active:text-primary
      dark:hover:bg-primary/85
      bg-primary
      border-primary
      text-white
      hover:bg-primary/90
      `
      }
        /* Additional styles from props */
    `}
  >
    {icon ? (
      <div className={`flex items-center ${title && 'space-x-1'} w-fit mx-auto`}>
        {title && <span>{title}</span>} <span>{icon}</span>
      </div>
    ) : (
      <span>{title}</span>
    )}
  </Button>
);

export default ButtonWithIcon;
