import { HistoryOutlined } from '@ant-design/icons';
import { CarbonReport, Export, MenuSecond, Monitoring, PrimeFileExport, SettingLight } from 'components/icons';
import { Link } from 'react-router-dom';
import { Log } from 'victory';
import { basePathSubDir } from './ApiErrorHandler';

export const relevantPath = (location) =>
  [
    '/home',
    '/driller-menu',
    '/driller-menu-v1',
    '/tank-menu',
    '/settings',
    '/export',
    '/about',
    '/monitoring',
    '/historical',
    'create-view',
    '/report-page',
  ].some((path) => location?.pathname.includes(path));

export const itemMenuHeader = (isDarkMode, selectedViewId) => [
  {
    key: '1',
    icon: <SettingLight color={isDarkMode ? '#FFFFFF' : undefined} />,
    label: (
      <Link to={`${basePathSubDir}/settings`}>
        <div className="pl-1">settings</div>
      </Link>
    ),
  },
  {
    key: '5',
    icon: <HistoryOutlined className="p-0 m-0 text-xl" color={isDarkMode ? '#FFFFFF' : undefined} />,
    label: (
      <a href={`${basePathSubDir}/historical`} className="pl-2">
        historical
      </a>
    ),
  },
  {
    key: '2',
    icon: <MenuSecond color={isDarkMode ? '#FFFFFF' : undefined} />,
    label: (
      <Link to={`${basePathSubDir}/driller-menu`} >
        <div className="pl-1">driller’s menu</div>
      </Link>
    ),
  },
  {
    key: '6 ',
    icon: <Monitoring color={isDarkMode ? '#FFFFFF' : undefined} />,
    label: (
      <Link to={`${basePathSubDir}/monitoring`}>
        <div className="pl-1">monitoring</div>
      </Link>
    ),
  },
  {
    key: '3',
    icon: <Log color={isDarkMode ? '#FFFFFF' : undefined} />,
    label: (
      <Link to={`${basePathSubDir}/logs`}>
        <div className="pl-1">Logs</div>
      </Link>
    ),
  },
  {
    key: '4',
    icon: <Export color={isDarkMode ? '#FFFFFF' : undefined} />,
    label: <span>Export</span>,
    children: [
      {
        key: '4-1',
        icon: <PrimeFileExport color={isDarkMode ? '#FFFFFF' : undefined} />,
        label: (
          <span className="pl-1">
            <Link to={`${basePathSubDir}/export`}>Export File</Link>
          </span>
        ),
      },
      {
        key: '4-2',
        icon: <CarbonReport color={isDarkMode ? '#FFFFFF' : undefined} />,
        label: (
          <span className="pl-1">
            <a href={selectedViewId ? `${basePathSubDir}/report-page?viewId=${selectedViewId}` : '/report-page'}>Visual Report</a>
          </span>
        ),
      },
    ],
  },
];
