import ApiToastHandle from 'containers/ApiToastHandle';

export default function ApiErrorHandler(error, status) {
  const errorStatus = error?.response?.data?.status_code || error?.response?.status;
  const errorMessage = error?.response?.data?.error;
  return ApiToastHandle(errorStatus, errorMessage, status || 'error');
}

export const isRemoteMode = (value) => value.includes('TRUE');
// _HOME_PAGE
// 
export const basePathSubDirCheck = "/".trim();
export const basePathSubDir = JSON.stringify(basePathSubDirCheck) === JSON.stringify("/") ? "" : '/';
export const socketBasePathSubDir = '';
